// {AssayEditor.tsx}

import React from 'react';
// import useStyles from './styles';
// import TEST_ID from './constants';
import { Box } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { AssayEditor as AssayEditorContent } from 'quil';

export interface AssayEditorProps {
  // Props
}

export default function AssayEditor() {
  // const { classes, cx } = useStyles();
  const location = useLocation();
  const id: string = location.state.protocolId;
  return (
    <Box width="100%" height="100%">
      <AssayEditorContent assayProtocolId={id} fullPage/>
    </Box>
  );
}
